import React  from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './InfoTile.scss';
import celular from './img/celular.png';
import {sessionGet, setUser, logoutConfirm} from './usuario/Auth';

class InfoTile extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      user: undefined
    };

    this.setUser = setUser.bind(this);
  }

  componentDidMount(){
    let email = sessionGet('email');
    if(email != null)
    this.setUser(email);
  }

  render () {

    let REF = this;

    const getUserGreeting = () => {

      if(typeof REF.state.user === "undefined" || Object.keys(REF.state.user).length == 0)
      return '';

      let greeting = REF.state.user.gender == "M" ? "Bem-vindo, "+this.state.user.name : "Bem-vinda, "+this.state.user.name;

      return (<>{greeting} <Button variant="warning" onClick={() => logoutConfirm()} className="ml-auto logout btn-sm">SAIR</Button></>);
    }

      return (
          <section className="InfoTile">
          <Container>
          <Row> 
            <Col className="text-center d-flex" xs="12" md="4">{getUserGreeting()}</Col>
            <Col className="text-center" md="4">
            </Col>
            <Col className="phone-list"  xs="12" md="4"> 
            <a href="https://api.whatsapp.com/send?phone=5531995774145&text=Ol%C3%A1!Vim%20pelo%20link%20do%20site...">
              <img src={celular} alt="telefone fixo" className="img-fluid" /> 31 995774145
            </a>
            </Col>
          </Row>
        </Container>
        </section>
      );
  }
}
  
export default InfoTile;
