import React from 'react';
import TestimonialModal from './TestimonialModal';  
import {Container, Row, Col} from 'react-bootstrap';
import InfoTile from './InfoTile';
import Header from './Header';  

class Testimonials extends React.Component   {
	
	constructor(props){
		super(props);

		this.state = {
            show: false,
            src: '',
            list: []
		};
	}

    componentDidMount(){
        let list = [];
        for(let c = 1; c <=32; c++){
            list.push('/testimonials/t-'+c+'.jpeg');
        }
            this.setState({ list });
    }

    setModalShow = (show, src) => {
        this.setState({ show, src });
    }

	render(){

        let REF = this;

        const listItems = () => {
           return this.state.list.map((i) => {

                return ( 
                <div className="col-6 col-sm-2 mt-3">
                    <img src={i} className="img-fluid" onClick={() => REF.setModalShow(true, i)} />
                </div>);
            });
        }
	
  return (
			<>
            <TestimonialModal
                src={this.state.src}
                show={this.state.show}             
                onHide={() => REF.setModalShow(false, '')}
              />
                <InfoTile></InfoTile>
               <Header></Header>
                  <Container>
        <img src="/img/5stars-title.png" className="img-fluid d-block mx-auto my-4" />
                  <Row>
                      <Col>
                      <p className="text-center">Sua avaliação é muito importante pra gente!</p>
                    <a className="btn btn-success m-auto d-block btn-lg" href="https://api.whatsapp.com/send?phone=5531995774145&text=Ol%C3%A1!Vim%20pelo%20link%20do%20site...">Envie-nos sua avaliação!</a>
               
                      </Col>
              </Row>
              <Row>
                {
                   listItems()
                }
                </Row>
                <Row>
                    <Col>
                    <p className="text-center">Sua avaliação é muito importante pra gente!</p>
                    <a className="btn btn-success m-auto d-block btn-lg" href="https://api.whatsapp.com/send?phone=5531995774145&text=Ol%C3%A1!Vim%20pelo%20link%20do%20site...">Envie-nos sua avaliação!</a>
                    </Col>
                </Row>
                </Container>
			</>
  );
	}
}

export default Testimonials;