import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.scss';
import fb from './img/fb.png';
import wp_call from './img/wp.png';
import ssl_icon from './img/ssl-seguro.png';
import twt from './img/twt.png';
import lin from './img/lin.png';
import insta from './img/insta.png';
import yt from './img/yt.png';

function About() {
    return (
        
      <section className="Footer">
      {/* Footer */}
      <footer className="page-footer font-small unique-color-dark">
        <div className="get-connected">
          <div className="container">
            {/* Grid row*/}
            <div className="row py-4 d-flex align-items-center">
              {/* Grid column */}
              <div className="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
                <h6 className="mb-0">Encontre-nos nas redes sociais!</h6>
              </div>
              {/* Grid column */}
              {/* Grid column */}
              <div className="col-md-6 col-lg-7 text-center text-md-right">
                           {/*Instagram*/}
                <a className="ins-ic" href="https://instagram.com/transbaixinho">
                <img src={insta} alt="nosso instagram"  />
                </a>
                   {/*YT*/}
                   <a className="ins-ic ml-3 d-none" href="https://youtube.com/transbaixinho">
                <img src={yt} alt="nosso canal"  />
                </a>
                {/* Facebook */}
                <a className="fb-ic ml-3" href="https://facebook.com/transbaixinho">
                    <img src={fb} alt="nosso facebook"  />
                </a>
                {/* Twitter */}
                <a className="tw-ic ml-3 d-none" href="https://twitter.com/transbaixinho">
                <img src={twt} alt="nosso twitter"  />
                </a>
      
                {/*Linkedin */}
                <a className="li-ic d-none" href="https://linkedin.com/transbaixinho">
                <img src={lin} alt="nosso linkedin"  />
                </a>
       
              </div>
              {/* Grid column */}
            </div>
            {/* Grid row*/}
          </div>
        </div>
        {/* Footer Links */}
        <div className="container text-center text-md-left mt-5">
          {/* Grid row */}
          <div className="row mt-3">
            {/* Grid column */}
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              {/* Content */}
              <h6 className="text-uppercase font-weight-bold">TRIP Viagens e Reservas</h6>
              <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: '120px'}} />
              <p>Bem-vindo ao novo site que preparamos pra você encontrar tudo o que precisa saber sobre nossa empresa.</p>
              <img src={ssl_icon} alt="banner ssl seguro" class="img-fluid w-50"/>
            </div>
            {/* Grid column */}
            {/* Grid column */}
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4" hidden>
              {/* Links */}
              <h6 className="text-uppercase font-weight-bold">Links úteis</h6>
              <hr className="deep-purple accent-2 mt-0 d-inline-block mx-auto" style={{width: '120px'}} />
              <ul className="links">
                <li>
            
                </li>
                <li>
                
                </li>
                <li>
             
                </li>
              </ul>

            </div>
        
            {/* Grid column */}
            {/* Grid column */}
            <div className="col-md-4 col-lg-5 col-xl-3 mx-auto mb-md-0 mb-4">
              {/* Links */}
              <h6 className="text-uppercase font-weight-bold">Contato</h6>
  
              <a href="https://api.whatsapp.com/send?phone=5531995774145&text=Ol%C3%A1!Vim%20pelo%20link%20do%20site...">
                <img alt="icone para falar via whatsapp" src={wp_call} class="img-fluid"/>
              </a>
              <p>
              Fone: 31 995774145
                 </p>
              <p>
                </p>
   
            </div>
            {/* Grid column */}
             
            {/* Grid column */}
            <div className="col-md-3 col-lg-4 col-xl-2 mx-auto mb-4">
              <p>Compra segura com PagSeguro</p>
            <img src="/img/pagseguro.png" className="img-fluid" alt="compra segura com PagSeguro" />
            </div>
   {/* Grid column */}
          </div>
          {/* Grid row */}
        </div>
        {/* Footer Links */}
        {/* Copyright */}
        <div className="container footer-copyright text-center py-3">
            <Row>
            <Col>
            © 2024 Copyright <strong> TRIP </strong>

                </Col>
                <Col>
                    Produzido por <a href="https://matteus.dev"><strong>Matteus Barbosa</strong></a>
                </Col>
            </Row>
        </div>
        {/* Copyright */}
      </footer>
      {/* Footer */}
    </section>
    );
}

export default About;
